import React, {
  useRef, useState, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useOnClickOutside } from '@hooks';
import { CartContext, UserContext, getInstructorsString } from '@helpers';
import MESSAGES from '@messages';
import theme from '../styled/theme';
import {
  Button,
  Box,
  Title,
  ParticipantsSelect,
} from '../index';
import InfoPanelCheckbox from './InfoPanelCheckbox';
import { IS_DEV } from '../../constants';

const StyledBox = styled(Box)`
  width: 100%;
  height: 100%;
`;

const StyledButton = styled(Button)`
    font-size: 0.7em;
    margin-top: 1em;
`;

const StyledTitle = styled(Title)`
  letter-spacing: 2px;
`;

const Panel = styled.div`
  position: absolute;
  width: ${theme.panel.width};
  min-height: ${theme.panel.height};
  left: 100%;
  height: 100%;
  background: white;
  border: 1px solid ${theme.colors.border};
  border-radius: ${theme.radius.small};
  box-shadow: ${theme.shadow};
  z-index: 11;

  ${(props) => props.small && `
    min-height: ${theme.panel.smallHeight};
  `}

  ${(props) => props.isNearRight && `
    left: 0;
    transform: translateX(-100%);
  `};
`;

const PanelInner = styled.div`
  min-height: ${theme.panel.height};
  display: flex;
  justify-content: space-ber;
  align-items: center;
  width: 100%;

  ${(props) => props.small && `
  min-height: ${theme.panel.smallHeight};
`}
`;

const SmallText = styled.div`
  line-height: 1.2;
  font-size: 0.75em;
`;

const SmallInfo = styled(SmallText)`
  color: ${theme.colors.red};
  margin: 4em 2em 0;
`;

const now = dayjs();
const month = now.month();
const nextMonth = now.add(1, 'month').month();
const dayOfMonth = now.date();
const mustHaveNextMonth = dayOfMonth >= 15;

const currentMonthLabel = MESSAGES.MONTH_NAMES[month];
const nextMonthLabel = MESSAGES.MONTH_NAMES[nextMonth];

const InfoPanel = ({ isNearRight, setOpen, events }) => {
  const [{
    name,
    start_time,
    end_time,
    instructors,
    excerpt,
    events_length,
  }] = events || [];

  const { current, next } = events.reduce((acc, item) => {
    if (item.month === month) acc.current = item;
    if (item.month === month + 1) acc.next = item;

    return acc;
  }, { current: {}, next: {} });

  const [participant, setParticipant] = useState('');
  const [currentMonthChecked, setCurrentMonthChecked] = useState(false);
  const [nextMonthChecked, setNextMonthChecked] = useState(false);
  const ref = useRef();
  const {
    addToCart, checkItem, checkPaidItem, nextPeriodItems = [],
  } = useContext(CartContext);
  const {
    isLogged, isSuperUser, client, upcommingMonthgroups, pageconfig,
  } = useContext(UserContext);
  const { is_cash_only } = client || {};

  const mayBuyUpcomming = upcommingMonthgroups.some((item) => participant === item.participant && next.id === item.id);

  useOnClickOutside(ref, () => setOpen(false));

  const fromNow = current.id && !nextPeriodItems
    .some((item) => item.id === current.id && item.participant === participant);

  const hasVacancy = current.vacancies > 0 || upcommingMonthgroups.some((item) => item.id === current.id);
  const hasNextVacancy = next.vacancies > 0;
  const itemData = {
    ...current,
    participant,
    from_now: fromNow,
  };

  const nextItemData = { ...next, participant };

  const onParticipantsChange = (value) => {
    setParticipant(value);
  };

  const cartHasItem = checkItem(itemData);
  const cartHasNextItem = checkItem(nextItemData);
  const paidCurrentMonth = checkPaidItem(itemData);
  const paidNextMonth = checkPaidItem(nextItemData);

  const hasItem = !participant || paidCurrentMonth || cartHasItem;
  const hasNextItem = !participant || paidNextMonth || cartHasNextItem;

  const getButtonLabel = () => {
    if (!participant) return MESSAGES.PICK_PARTICIPANT;
    // if (!hasVacancy || !hasNextVacancy) return 'Zapisz na listę rezerwową';

    return MESSAGES.ADD_TO_CART;
  };

  useEffect(() => {
    if (participant && !hasItem && hasVacancy) {
      setCurrentMonthChecked(true);
    } else {
      setCurrentMonthChecked(false);
    }
  }, [hasItem, participant, hasVacancy]);

  const showNextMonthCheckbox = (currentMonthChecked && mustHaveNextMonth) || mayBuyUpcomming;

  useEffect(() => {
    const nextMonthCheckedValue = participant && showNextMonthCheckbox && hasNextVacancy;
    setNextMonthChecked(nextMonthCheckedValue);
  }, [hasNextItem, participant, hasNextVacancy, showNextMonthCheckbox]);

  const paymentsDisabled = pageconfig?.payments_disabled;

  return (
    <Panel
      isNearRight={isNearRight}
      small={!isLogged}
    >
      <PanelInner ref={ref} small={!isLogged}>
        <StyledBox
          fontSize={1}
          py={[1, 2, 3]}
          px={[1, 2]}
        >
          <p>{`${start_time} - ${end_time}`}</p>
          <StyledTitle
            tag="h6"
            fontSize={0}
            color="yellow"
            mb={[1, 2]}
          >
            {name}
          </StyledTitle>
          <Box fontSize={0}>{excerpt}</Box>
          {IS_DEV ? (
            <SmallText>
              {current.id}
            </SmallText>
          ) : null}
          {instructors.length && (
            <p>
              {getInstructorsString(instructors)}
            </p>
          )}
          <p style={{ fontSize: 10, margin: 0, padding: 0 }}>
            Zajęć w miesiącu:
            {' '}
            <span>{events_length}</span>
          </p>
          {!paymentsDisabled && isLogged && (!is_cash_only || isSuperUser) ? (
            <>
              <hr />
              <Box>
                <ParticipantsSelect onChange={onParticipantsChange} />
                {current && (
                  <InfoPanelCheckbox
                    name="currentMonth"
                    label={`${MESSAGES.CURRENT_MONTH}${currentMonthLabel ? ` (${currentMonthLabel})` : ''}`}
                    value={currentMonthChecked}
                    disabled={!participant || hasItem || !hasVacancy}
                    onChange={setCurrentMonthChecked}
                    hasItem={hasItem}
                    paid={paidCurrentMonth}
                    hasVacancy={hasVacancy}
                    item={itemData}
                  />
                )}
                {/* {showNextMonthCheckbox && (
                  <InfoPanelCheckbox
                    name="nextMonth"
                    label={`${MESSAGES.NEXT_MONTH}${nextMonthLabel ? ` (${nextMonthLabel})` : ''}`}
                    value={nextMonthChecked}
                    disabled={!mayBuyUpcomming}
                    onChange={setNextMonthChecked}
                    hasItem={hasNextItem}
                    paid={paidNextMonth}
                    hasVacancy={hasNextVacancy}
                    item={nextItemData}
                  />
                )}
                {showNextMonthCheckbox && nextMonthChecked && !mayBuyUpcomming && (
                  <SmallText>
                    {MESSAGES.MUST_HAVE_NEXT_MONTH_EVENTS_INFO}
                  </SmallText>
                )} */}
                <StyledButton
                  color="red"
                  text={getButtonLabel()}
                  Tag="button"
                  disabled={!participant
                    || (!currentMonthChecked && !nextMonthChecked)
                    || (hasItem && currentMonthChecked)
                    || (hasNextItem && nextMonthChecked)}
                  onClick={() => {
                    const items = [];
                    if (currentMonthChecked) items.push(itemData);
                    if (nextMonthChecked) items.push(nextItemData);

                    addToCart(items);
                  }}
                />
              </Box>
            </>
          ) : (
            <SmallInfo>
              {paymentsDisabled ? MESSAGES.PAYMENTS_DISABLED_INFO : is_cash_only
                ? MESSAGES.CASH_ONLY_PAYMENT_INFO
                : MESSAGES.UNAUTHORIZED_PAYMENT_INFO}
            </SmallInfo>
          )}
        </StyledBox>
      </PanelInner>
    </Panel>
  );
};

InfoPanel.propTypes = {
  isNearRight: PropTypes.bool,
  setOpen: PropTypes.func,
  events: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    group_id: PropTypes.number,
    agenda_id: PropTypes.number,
    vacancies: PropTypes.number,
    setOpen: PropTypes.func,
    name: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    instructors: PropTypes.arrayOf(PropTypes.string),
    excerpt: PropTypes.string,
    isNearRight: PropTypes.bool,
    day_index: PropTypes.number,
    events_length: PropTypes.number,
  })),
};

export default InfoPanel;
