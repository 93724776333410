import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@loform/react';
import { VOUCHERS_ERRORS, NEW_API_URL } from '@constants';
import {
  Checkbox, CartTable, SmallButton, RodoCheckbox, DateTimePicker,
} from '@components';
import {
  UserContext, CartContext, CalendarContext,
} from '@helpers';
import MESSAGES from '@messages';
import styled from 'styled-components';
import Form from './Form';
import VoucherForm from './VoucherForm';
import owuPdf from '../../assets/docs/01.09.2024/owu.pdf';
import regulaminPdf from '../../assets/docs/30.08.2019/regulamin.pdf';

const errorsMap = {
  ...VOUCHERS_ERRORS,
  participant_not_found: MESSAGES.PARTICIPANT_NOT_FOUND,
  no_vacancies: MESSAGES.CART_NO_VACANCIES,
  paid_classes: MESSAGES.CART_SOME_CLASSES_PAID,
  operation_date_required: MESSAGES.OPERATION_DATE_REQUIRED,
};

const EmptyCartText = styled.h4`
  text-align: center;
  margin: 3em 0;
`;

const UndoButton = ({ undoTimeLeft, undoCart }) => (
  !!undoTimeLeft && (
    <SmallButton
      color="yellow"
      small
      type="button"
      Tag="button"
      style={{
        marginLeft: 'auto',
        marginRight: 0,
        display: 'block',
      }}
      onClick={undoCart}
    >
      {MESSAGES.UNDO}
      {' '}
      (
      {undoTimeLeft}
      )
    </SmallButton>
  )
);

UndoButton.propTypes = {
  undoTimeLeft: PropTypes.number,
  undoCart: PropTypes.func.isRequired,
};

const noop = () => { };

const CartForm = ({ closePortal = noop, isOpen }) => {
  const delayedClose = () => setTimeout(closePortal, 1000);

  const { clearUpcomming = noop, client: { participants = {} } = {}, isSuperUser } = useContext(UserContext);
  const { fromDate, setEvents, pageconfig } = useContext(CalendarContext);
  const {
    items,
    amount,
    removeFromCart,
    undoTimeLeft,
    undoCart,
    forceClearCart = noop,
    voucher,
    setVoucher,
  } = useContext(CartContext);

  const [voucherState, setVoucherState] = useState(!!voucher.code);
  const [voucherInputValue, setVoucherInputValue] = useState(voucher.code);
  const [isVoucherOk, setVoucherOk] = useState(voucher.ok);

  const paymentsDisabled = pageconfig.payments_disabled || false;

  if (!items.length && isOpen && undoTimeLeft <= 0) delayedClose();

  const hasCustomErrors = items.some(({ vacancies, paid }) => paid || vacancies <= 0);

  if (paymentsDisabled) return MESSAGES.PAYMENTS_DISABLED_INFO;

  return (
    <Form
      url={`${NEW_API_URL}/payment`}
      buttonText={isSuperUser ? MESSAGES.SUBMIT : MESSAGES.GO_TO_PAYMENT}
      title={MESSAGES.FINALIZING_ORDER}
      hasCustomErrors={hasCustomErrors}
      buttonProps={{
        disabled: voucherState && !isVoucherOk,
      }}
      replaceContent={!items.length ? () => (
        <>
          <UndoButton undoTimeLeft={undoTimeLeft} undoCart={undoCart} />
          <EmptyCartText>{MESSAGES.CART_IS_EMPTY}</EmptyCartText>
        </>
      ) : null}
      onSuccess={async ({ data = {}, setMessage }) => {
        const { ok, control } = data;
        if (ok && control) {
          setMessage({ success: MESSAGES.REDIRECTING });
          if (typeof forceClearCart === 'function') forceClearCart();
          if (typeof clearUpcomming === 'function') clearUpcomming();
          if (typeof closePortal === 'function') closePortal();
          if (typeof window !== 'undefined') {
            window.location = `/podsumowanie?id=${control}`;
          }
        }
      }}
      onError={({ data: { events = [] } = {} } = {}) => {
        if (events && events.length) setEvents(events);
      }}
      errorsMap={errorsMap}
    >
      {({ errors }) => (
        <>
          <UndoButton undoTimeLeft={undoTimeLeft} undoCart={undoCart} />
          <CartTable
            items={items}
            amount={amount}
            removeFromCart={removeFromCart}
            participants={participants}
            setVoucher={setVoucher}
            voucher={voucher}
          />
          {/* {isSuperUser && <DateTimePicker />} */}
          <VoucherForm
            voucher={voucher}
            setVoucher={setVoucher}
            voucherState={voucherState}
            setVoucherState={setVoucherState}
            voucherInputValue={voucherInputValue}
            setVoucherInputValue={setVoucherInputValue}
            isVoucherOk={isVoucherOk}
            setVoucherOk={setVoucherOk}
          />
          <Input
            type="hidden"
            name="items"
            value={JSON.stringify(items.map(({ id, from_now, participant }) => ({ id, from_now, participant })))}
          />
          <Input
            type="hidden"
            name="fromDate"
            value={fromDate}
          />
          <Input
            type="hidden"
            name="voucher"
            value={JSON.stringify(voucher)}
          />
          {/* TODO: move to separate component */}
          <Checkbox
            errors={errors}
            name="regulations"
            required
            small
            render={() => (
              <>
                Oświadczam, ze zapoznałem się z  umową wygenerowaną na niniejszej
                stronie internetowej oraz z
                {' '}
                <a href={owuPdf} target="blank">Ogólnymi Warunkami Umów</a>
                , a nadto akceptuję
                {' '}
                <a href={regulaminPdf} target="blank">Regulamin Obiektu</a>
                , w którym prowadzone będą zajęcia. Oświadczam że
                zapoznałem się tymi dokumentami.
              </>
            )}
          />
          <RodoCheckbox errors={errors} />
        </>
      )}
    </Form>
  );
};

CartForm.propTypes = {
  closePortal: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default CartForm;
